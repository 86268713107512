import React, {useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import AddGroupeExamen from "./AddGroupeExamen"
import DataTableGroupeExamen from "./DataTableGroupeExamen"
import SeanceExamen from "./SeanceExamen";
import SeanceExamenAdd from "./SeanceExamenAdd";
import EditSalleExamen from "./EditSalleExamen";
import ChevauchementGroupeSem from "./ChevauchementGroupeSem";

const GroupeExamen = props => {
  const [section, setSection] = useState(1)
  const [add, setAdd] = useState(0);
  const [cloture, setCloture] = useState(0);

  useEffect(async () => {
    var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
    setCloture(cloture_annee)
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Groupe d'examen")
    );
    setAdd(typeArticleSubMenu.sous_menu[0].add)

  }, [])
  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <DataTableGroupeExamen/>
    } else if (section === 2) {
      return <AddGroupeExamen setSection={setSection} back={back} />
    }else if (section === 3) {
      return <SeanceExamen setSection={setSection} back={back} />
    }else if(section === 4 ){
      return <SeanceExamenAdd setSection={setSection} back={back} />
    }else if(section === 5 ){
      return <EditSalleExamen setSection={setSection} back={back} />
    }else if(section === 6 ){
      return <ChevauchementGroupeSem setSection={setSection} back={back} />
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Groupe examen | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  {"Groupe d'examen"}
                </NavLink>
              </NavItem>
              {cloture !== 1 ? (
                  add === 1 ? (
                      <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              active: section === 2,
                            })}
                            onClick={() => {
                              setSection(2)
                            }}
                        >
                          {"Ajouter Groupe d'examen"}
                        </NavLink>
                      </NavItem>
                  ) : null
              ) : null}
              <NavItem>
                <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === 3,
                    })}
                    onClick={() => {
                      setSection(3)
                    }}
                >
                  {"Seance d'examen"}
                </NavLink>
              </NavItem>
              {cloture !== 1 ? (
                  add === 1 ? (
                      <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              active: section === 4,
                            })}
                            onClick={() => {
                              setSection(4)
                            }}
                        >
                          {"Ajouter Seance d'examen"}
                        </NavLink>
                      </NavItem>
                  ) : null
              ) : null}
              {cloture !== 1 ? (
                  add === 1 ? (
                      <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              active: section === 5,
                            })}
                            onClick={() => {
                              setSection(5)
                            }}
                        >
                          {"Modification de salle pour examen"}
                        </NavLink>
                      </NavItem>
                  ) : null
              ) : null}
              <NavItem>
                <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === 6,
                    })}
                    onClick={() => {
                      setSection(6)
                    }}
                >
                  {"Vérification du chevauchement de groupe"}
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(GroupeExamen)

import React, {useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
import AddEnseignant from "./AddEnseignant"
import DataTableEnseignant from "./DataTableEnseignant"
import ExportEnsiegnant from "./ExportEnsiegnant"
import ImportEnseignant from "./ImportEnseignant"
import FicheVoeauxList from "./FicheVoeauxList";
import {Fragment} from "@fullcalendar/core";
import FicheVoeuxSurveillance from "./FicheVoeuxSurveillance";
import PermuterGroupe from "./PermuterGroupe";
import AffectationSem from "./AffectationSem";

const Enseignant = props => {
    const [section, setSection] = useState(1)
    const [add, setAdd] = useState(0);
    const [excel, setExcel] = useState(0);
    const [cloture, setCloture] = useState(0);
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const back = () => {
        setSection(1)
    }
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Enseignants")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        setExcel(typeArticleSubMenu.sous_menu[0].exel)
    }, [])

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableEnseignant/>
        } else if (section === 2) {
            return <AddEnseignant setSection={setSection} back={back}/>
        } else if (section === 3) {
            return <ExportEnsiegnant/>
        } else if (section === 4) {
            return <ImportEnseignant/>
        } else if (section === 5) {
            return <FicheVoeauxList/>
        }else if(section === 6) {
            return <FicheVoeuxSurveillance/>
        }else if(section === 7) {
            return <PermuterGroupe setSection={setSection} back={back} />
        }else if(section === 8) {
            return <AffectationSem setSection={setSection} back={back} />
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Enseignant | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    Enseignant
                                </NavLink>
                            </NavItem>
                            {cloture != 1 ? (
                                add === 1 ? (
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 2,
                                            })}
                                            onClick={() => {
                                                setSection(2)
                                            }}
                                        >
                                            Ajouter enseignant
                                        </NavLink>
                                    </NavItem>
                                ) : (null)
                            ) : (null)}
                            {excel === 1 ? (
                                <Fragment>
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 3,
                                            })}
                                            onClick={() => {
                                                setSection(3)
                                            }}
                                        >
                                            Exportation
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 4,
                                            })}
                                            onClick={() => {
                                                setSection(4)
                                            }}
                                        >
                                            Importation
                                        </NavLink>
                                    </NavItem>
                                </Fragment>
                            ) : (null)}
                            {add === 1 ? (
                                <Fragment>
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 5,
                                            })}
                                            onClick={() => {
                                                setSection(5)
                                            }}
                                        >
                                            Fiche Voeaux
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 6,
                                            })}
                                            onClick={() => {
                                                setSection(6)
                                            }}
                                        >
                                            Fiche Voeaux surveillance
                                        </NavLink>
                                    </NavItem>
                                </Fragment>

                            ) : (null)}
                            {cloture !== 1 ? (
                                add === 1 ? (
                                    <Fragment>

                                        <NavItem>
                                            <NavLink
                                                style={{cursor: "pointer"}}
                                                className={classnames({
                                                    active: section === 8,
                                                })}
                                                onClick={() => {
                                                    setSection(8)
                                                }}
                                            >
                                                Listes affectations
                                            </NavLink>
                                        </NavItem>
                                    </Fragment>

                                ) : null
                            ) : null}
                            {cloture !== 1 ? (
                                add === 1 ? (
                                    <Fragment>

                                        <NavItem>
                                            <NavLink
                                                style={{cursor: "pointer"}}
                                                className={classnames({
                                                    active: section === 7,
                                                })}
                                                onClick={() => {
                                                    setSection(7)
                                                }}
                                            >
                                                Permutation de groupe entre les enseignants
                                            </NavLink>
                                        </NavItem>
                                    </Fragment>

                                ) : null
                            ) : null}
                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Enseignant)

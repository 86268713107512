import React, {useEffect, useState} from "react"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import {withRouter} from "react-router-dom"
import PropTypes from "prop-types"
import Select from "react-select"
//i18n
import {withTranslation} from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label, Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap"
import getDay from "date-fns/getDay"
import Switch from "react-switch";
import {AvForm} from "availity-reactstrap-validation";

const EditParametrageExamen = props => {
    const [date, setDate] = useState(new Date())
    const [dateFin, setDateFin] = useState(new Date());
    const [semestre, setSemestre] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
        {value: 3, label: "Session Contôle", section: 2},
    ])
    const [etat, setEtat] = useState(1)
    const [loading, setLoading] = useState(false)
    const [selectSemestre, setSelectSemestre] = useState("")
    const [modalDelete, setModalDelete] = useState(false);
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    useEffect(async () => {
        var url = window.location.href
        var array = url.split("=")
        var id = array[1]
        const res = await API.get(`examen_parametrage/getById/${id}`).then(res => {
            setDate(new Date(res.data.param.date_debut))
            setDateFin(new Date(res.data.param.date_fin))
            setEtat(res.data.param.active)
            setSelectSemestre(res.data.param.semestre)
            setLoading(true)
        })
    }, [])


    const save = async () => {

        // convert date début
        let dateRatt = date
        let month = "" + (dateRatt.getMonth() + 1)
        let day = "" + dateRatt.getDate()
        let year = dateRatt.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        let convertDate = [year, month, day].join("-")
        // convert date fin
        let dateFinConv = dateFin
        let monthFin = "" + (dateFinConv.getMonth() + 1)
        let dayFin = "" + dateFinConv.getDate()
        let yearFin = dateFinConv.getFullYear()
        if (monthFin.length < 2) monthFin = "0" + monthFin
        if (dayFin.length < 2) dayFin = "0" + dayFin
        let convertDateFin = [yearFin, monthFin, dayFin].join("-")
        //
        const res = await API.post("examen_parametrage/add", {
            date_debut: convertDate,
            date_fin: convertDateFin,
            semestre_id: selectSemestre.value,
            active: etat,
            annee_id,
            user_id
        })
            .then(res => {
                props.history.push("/ParametrageExamen")
            })
            .catch(() => {
                toast.error("Problème lors de l'insertion !", {
                    containerId: "A",
                })
            })

    }
    const toggleDelete = () => {
        setModalDelete(!modalDelete);
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Card>
                            <CardBody>
                                {loading ? (
                                    <AvForm>
                                        <Form>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            {props.t("Semestre")}
                                                        </Label>
                                                        <Select
                                                            isDisabled={true}
                                                            options={semestre}
                                                            isSearchable={true}
                                                            value={selectSemestre}
                                                            onChange={e => setSelectSemestre(e)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            {props.t("Date début")}
                                                            <span style={{color: "red", paddingLeft: "5px"}}>*</span>
                                                        </Label>
                                                        <DatePicker
                                                            className="form-control ddate"
                                                            selected={date}
                                                            value={date}
                                                            onChange={e => setDate(e)}
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">
                                                            {props.t("Date fin")}
                                                            <span style={{color: "red", paddingLeft: "5px"}}>*</span>
                                                        </Label>
                                                        <DatePicker
                                                            className="form-control ddate"
                                                            selected={dateFin}
                                                            value={dateFin}
                                                            onChange={e => setDateFin(e)}
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="d-block mb-3">Etat :</Label>
                                                        <div className="form-check form-check-inline">
                                                            <Input
                                                                type="radio"
                                                                id="customRadioInline1"
                                                                name="customRadioInline1"
                                                                className="form-check-input"
                                                                checked={etat === 1}
                                                                onChange={e => setEtat(1)}
                                                            />
                                                            <Label
                                                                className="form-check-label"
                                                                htmlFor="customRadioInline1"
                                                            >
                                                                Activé
                                                            </Label>
                                                        </div>
                                                        &nbsp;
                                                        <div className="form-check form-check-inline">
                                                            <Input
                                                                type="radio"
                                                                id="customRadioInline2"
                                                                name="customRadioInline1"
                                                                className="form-check-input"
                                                                checked={etat === 2}
                                                                onChange={e => setEtat(2)}
                                                            />
                                                            <Label
                                                                className="form-check-label"
                                                                htmlFor="customRadioInline2"
                                                            >
                                                                Désactivé
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {" "}
                                            <Col lg="6">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}
                                                    className="text-center mt-4"
                                                >
                                                    <Button
                                                        type="button"
                                                        color="warning"
                                                        className="btn btn-warning  mb-2 me-2"
                                                        onClick={() => props.history.push("/ParametrageExamen")}

                                                    >
                                                        {props.t("Annuler")}
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                    }}
                                                    className="text-center mt-4"
                                                >
                                                    <button type="button" className="btn btn-primary " onClick={toggleDelete}>
                                                        {props.t("Confirmer")}
                                                    </button>
                                                </div>
                                            </Col>
                                        </div>

                                    </AvForm>
                                ) : (
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {" "}
                                            <Spinner type="grow" className="ms-6" color="primary"/>
                                        </div>
                                        <h4
                                            style={{textAlign: "center", marginTop: "2%"}}
                                            className="ms-6"
                                        >
                                            {" "}
                                            {"Chargement"} ...{" "}
                                        </h4>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Row>
                    <ToastContainer
                        transition={Slide}
                        enableMultiContainer
                        containerId={"A"}
                        position={toast.POSITION.TOP_RIGHT}
                        autoClose={2500}
                    />
                </Container>
            </div>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleDelete}
                    tag="h4"
                >
                    {" Modification de date de paramétrage d'examen"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <h3 style={{color: "red"}}>
                            {"NB : Cette action est irréversible. Si vous modifiez cette date de paramétrage d'examen, toutes les séances d'examen hors la période que vous avez saisie seront supprimées automatiquement !!"}
                        </h3>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>{"Voulez-vous modifier la date de paramétrage d'examen ?"}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={save}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggleDelete}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}
export default withRouter(withTranslation()(EditParametrageExamen))
EditParametrageExamen.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    t: PropTypes.any,
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, Modal, ModalHeader, ModalBody } from "reactstrap"
import { withRouter } from "react-router"
import paginationFactory from "react-bootstrap-table2-paginator";

const DataTableHistoriqueActivationEnseignant = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [modal, setModal] = useState(false)
  const [description, setDescription] = useState("")
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "user",
      text: "User",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant",
      text: "Enseignant",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },

  ]
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.post("enseignant/historique_activation",{annee_id}).then(res => {
      setOrders(res.data.historique)
      setLoading(true)
    })
  }, [])

  const toggle = row => {
    setModal(!modal)
    setDescription(row.designation)
  }

  return (
      <React.Fragment>
        <div>
          {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                  >
                    {toolkitProps => (
                        <div>
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              pagination={paginationFactory(pageOptions)}
                              hover
                              {...toolkitProps.baseProps}
                          />
                        </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                >
                  {" "}
                  En cours ...{" "}
                </h4>
              </div>
          )}
        </div>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader
              style={{ width: "100% !important", textAlign: "center !important" }}
              toggle={toggle}
              tag="h4"
          >
            Historique
          </ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {/* {description} */}
          </ModalBody>
        </Modal>
      </React.Fragment>
  )
}

export default withRouter(DataTableHistoriqueActivationEnseignant)
DataTableHistoriqueActivationEnseignant.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}

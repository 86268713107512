import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Col, Row, Label, Form, Button } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"

const AffectationModuleMatiere = props => {
  const [matieres, setMatieres] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [modules, setModules] = useState([])
  const [selectModule, setSelectModule] = useState("")
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  //
  useEffect(async () => {
    const res = await API.get("matiere/select_affectation").then(res => {
      setMatieres(res.data.Matieres)
    })
    //
    const resM = await API.get("module/select").then(resM => {
      setModules(resM.data.Module)
    })
  }, [])

  const save = async () => {
    var module_id = selectModule.value
    if (selectModule == "") {
      toast.error("⛔ Module obligatoire", {
        containerId: "A",
      })
    } else if (selectMatiere != "") {
      const res = await API.post("matiere/add_affectation", {
        module_id: module_id,
        matieres: selectMatiere,user_id,annee_id
      }).then(res => {
        props.setSection(3)
      })
    } else {
      toast.error("⛔ Matière obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Module :</Label>
                <Select
                  options={modules}
                  isSearchable={true}
                  onChange={e => setSelectModule(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label
                  style={{ color: "#556ee6" }}
                  for="basicpill-firstname-input1"
                >
                  Matiere :
                </Label>
                <Select
                  isMulti={true}
                  options={matieres}
                  isSearchable={true}
                  onChange={e => setSelectMatiere(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                CONFIRMER
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AffectationModuleMatiere
AffectationModuleMatiere.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}

import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"

const AddGrade = props => {
  const [grade, setGrade] = useState("")
  const [nbrCours, setNbrCours] = useState("")
  const [nbrTD, setNbrTD] = useState("")
  const [nbrTP, setNbrTP] = useState("")
  const [autre, setAutre] = useState("")
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  const save = async () => {
    if(nbrCours ==""){
      toast.error("⛔ Nb Cours obligatoire", {
        containerId: "A",
      })
    }else if(nbrTD == ""){
      toast.error("⛔ Nb TD obligatoire", {
        containerId: "A",
      })
    }else if(nbrTP == ""){
      toast.error("⛔ Nb TP obligatoire", {
        containerId: "A",
      })
    }else if(autre == ""){
      toast.error("⛔ Nb Autre obligatoire", {
        containerId: "A",
      })
    }

      else if (grade != "") {
      const res = await API.post("grade/add", {
        name: grade,
        nb_cours: nbrCours,
        nb_td: nbrTD,
        nb_tp: nbrTP,
        autre: autre,
        user_id,
        annee_id
      }).then(res => {
        props.setSection(1)
      })
    } else {
      toast.error("⛔ Grade obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Form>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Grade")} :
                  </Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    placeholder={props.t("Grade")}
                    onChange={e => setGrade(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Nombre heure cours :
                  </Label>
                  <Input
                    lg="3"
                    type="number"
                    className="form-control"
                    placeholder="Nbr heure cours"
                    onChange={e => setNbrCours(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Nombre heure TD :
                  </Label>
                  <Input
                    lg="3"
                    type="number"
                    className="form-control"
                    placeholder="Nbr heure TD"
                    onChange={e => setNbrTD(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Nombre heure TP :
                  </Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    placeholder="Nbr heure TP"
                    onChange={e => setNbrTP(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Nombre heure CI :
                  </Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    placeholder="Nbr heure autre"
                    onChange={e => setAutre(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={save}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </AvForm>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddGrade)
AddGrade.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}

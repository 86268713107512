import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"

import CalendrierSurveillanceSem from "./CalendrierSurveillanceSem";
import CalendrierSurveillanceDetailSem from "./CalendrierSurveillanceDetailSem";
import DataTableEnseignantSem from "./DataTableEnseignantSem";
import DataTableEnseignantSurveillanceSem from "./DataTableEnseignantSurveillanceSem";

const CalendrierSurveillance = props => {
  const [section, setSection] = useState(3)

  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <CalendrierSurveillanceSem/>
    }else if(section === 2 ){
      return <CalendrierSurveillanceDetailSem/>
    }else if(section === 3 ){
      return <DataTableEnseignantSem/>
    }else if(section === 4 ){
      return <DataTableEnseignantSurveillanceSem/>
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Calendrier surveillance | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 3,
                  })}
                  onClick={() => {
                    setSection(3)
                  }}
                >
                 Enseignant surveillance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 4,
                  })}
                  onClick={() => {
                    setSection(4)
                  }}
                >
                 Charge horaire des enseignants pour surveillance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  Calendrier surveillance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 2,
                  })}
                  onClick={() => {
                    setSection(2)
                  }}
                >
                  Détail surveillance
                </NavLink>
              </NavItem>

            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CalendrierSurveillance)

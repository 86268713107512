import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Col, Row, Label, Form, Button } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"

const AffectationModuleSpec = props => {
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [diplome, setDiplome] = useState([])
  const [selectDiplome, setSelectDiplome] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [modules, setModules] = useState([])
  const [selectModule, setSelectModule] = useState("")
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  //
  useEffect(async () => {
    const res = await API.get("cycle/select").then(res => {
      setCycle(res.data.Cycle)
    })
    const resM = await API.get("module/select").then(resM => {
      setModules(resM.data.Module)
    })
  }, [])

  const getDiplome = async event => {
    setSelectDiplome("")
    setSelectSpecialite("")
    setSelectNiveau("")
    setSelectModule("")
    setSelectCycle(event)
    var cycle_id = event.value
    const res = await API.post("diplome/get_by_cycle", { id: cycle_id }).then(
      res => {
        setDiplome(res.data.Diplomes_By_Cycle)
      }
    )
  }

  const getSpec = async event => {
    setSelectSpecialite("")
    setSelectNiveau("")
    setSelectModule("")
    setSelectDiplome(event)
    var diplome_id = event.value
    const res = await API.post("specialite/get_by_diplome", {
      id: diplome_id,
    }).then(res => {
      setSpecialite(res.data.Specialites_By_Diplome)
    })
  }

  const getNiveau = async event => {
    setSelectNiveau("")
    setSelectModule("")
    setSelectSpecialite(event)
    var spec_id = event.value
    const res = await API.post("niveau/get_by_specialite", {
      id: spec_id,
    }).then(res => {
      setNiveau(res.data.Niveaux_by_specialite)
    })
  }

  const save = async () => {
    var cycle_id = selectCycle.value
    var specialite_id = selectSpecialite.value
    var niveau_id = selectNiveau.value
    if (selectCycle == "") {
      toast.error("⛔ Cycle obligatoire", {
        containerId: "A",
      })
    } else if (selectDiplome == "") {
      toast.error("⛔ Diplôme obligatoire", {
        containerId: "A",
      })
    } else if (selectSpecialite == "") {
      toast.error("⛔ Spécialité obligatoire", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      toast.error("⛔ Niveau obligatoire", {
        containerId: "A",
      })
    } else if (selectModule != "") {
      const res = await API.post("module/affectation", {
        cycle_id: cycle_id,
        spec_id: specialite_id,
        niveau_id: niveau_id,
        modules: selectModule,
        user_id,annee_id
      }).then(res => {
        props.setSection(3)
      })
    } else {
      toast.error("⛔ Module obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Cycle :</Label>
                <Select
                  options={cycle}
                  isSearchable={true}
                  onChange={e => getDiplome(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Diplôme :</Label>
                <Select
                  options={diplome}
                  value={selectDiplome}
                  isSearchable={true}
                  onChange={e => getSpec(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Spécialité :</Label>
                <Select
                  options={specialite}
                  value={selectSpecialite}
                  isSearchable={true}
                  onChange={e => getNiveau(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Niveau/Semestre :
                </Label>
                <Select
                  options={niveau}
                  value={selectNiveau}
                  isSearchable={true}
                  onChange={e => setSelectNiveau(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label
                  style={{ color: "#556ee6" }}
                  for="basicpill-firstname-input1"
                >
                  Module :
                </Label>
                <Select
                  isMulti={true}
                  options={modules}
                  value={selectModule}
                  isSearchable={true}
                  onChange={e => setSelectModule(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                Confirmer
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AffectationModuleSpec
AffectationModuleSpec.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}

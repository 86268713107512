import React, {Fragment, useEffect, useState} from "react"
import {Button, CardTitle, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap"
import PropTypes from "prop-types"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select";
import API from "../../api";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const EditSalleExamen = props => {
    const [niveau, setNiveau] = useState([]);
    const [selectNiveau, setSelectNiveau] = useState("")
    const [semestre, setSemestre] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
        {value: 3, label: "Session Contôle", section: 2},
    ])
    const [selectSemestre, setSelectSemestre] = useState("")
    const [specialite, setSpecialite] = useState([]);
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [date, setDate] = useState(new Date())
    const [quart, setQuart] = useState([]);
    const [selectQuart, setSelectQuart] = useState("");
    const [groupe, setGroupe] = useState([]);
    const [selectGroupe, setSelectGroupe] = useState("");
    const [matiere, setMatiere] = useState([]);
    const [selectMatiere, setSelectMatiere] = useState("");
    const [semestrePermuter, setSemestrePermuter] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
        {value: 3, label: "Session Contôle", section: 2},
    ])
    const [selectSemestrePermuter, setSelectSemestrePermuter] = useState("");
    const [niveauPermuter, setNiveauPermuter] = useState([]);
    const [selectNiveauPermuter, setSelectNiveauPermuter] = useState("");
    const [specialitePermuter, setSpecialitePermuter] = useState([]);
    const [selectSpecialitePermuter, setSelectSpecialitePermuter] = useState("");
    const [datePermuter, setDatePermuter] = useState(new Date())
    const [quartPermuter, setQuartPermuter] = useState([]);
    const [selectQuartPermuter, setSelectQuartPermuter] = useState("");
    const [groupePermuter, setGroupePermuter] = useState([]);
    const [selectGroupePermuter, setSelectGroupePermuter] = useState("");
    const [matierePermuter, setMatierePermuter] = useState([]);
    const [selectMatierePermuter, setSelectMatierePermuter] = useState("");
    const [arrayDetail, setArrayDetail] = useState("");
    const [arrayDetailPermuter, setArrayDetailPermuter] = useState("");
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    useEffect(async () => {
        const res = await API.get("niveau/select").then(res => {
            setNiveau(res.data.Niveau)
            setNiveauPermuter(res.data.Niveau)
        })
        const resQuart = await API.post("quart_examen/liste", {annee_id}).then(resQuart => {
            setQuart(resQuart.data.QuartExamen)
            setQuartPermuter(resQuart.data.QuartExamen)
        })
    }, [])
    const getSemestre = async (e) => {
        setSelectSemestre(e)
        setSelectNiveau("")
        setSelectSpecialite("")
        setSelectMatiere("")
        setDate(new Date())
        setSelectQuart("")
        setSelectGroupe("")
    }
    const getSemestrePermuter = async (e) => {
        setSelectSemestrePermuter(e)
        setSelectNiveauPermuter("")
        setSelectSpecialitePermuter("")
        setSelectMatierePermuter("")
        setDatePermuter(new Date())
        setSelectQuartPermuter("")
        setSelectGroupePermuter("")
    }
    const getSpecialite = async (e) => {
        setSelectNiveau(e)
        setSelectSpecialite("")
        setSelectMatiere("")
        setDate(new Date())
        setSelectQuart("")
        setSelectGroupe("")
        const res = await API.post("group/get_list_spec", {
            niveau_id: e.value,
            semestre_id: [selectSemestre],
        }).then(res => {
            setSpecialite(res.data.Groups)
        })
    }
    const getSpecialitePermuter = async (e) => {
        setSelectNiveauPermuter(e)
        setSelectSpecialitePermuter("")
        setSelectMatierePermuter("")
        setDatePermuter(new Date())
        setSelectQuartPermuter("")
        setSelectGroupePermuter("")
        const res = await API.post("group/get_list_spec", {
            niveau_id: e.value,
            semestre_id: [selectSemestrePermuter],
        }).then(res => {
            setSpecialitePermuter(res.data.Groups)
        })
    }
    const getGroupe = async (e) => {
        setSelectQuart(e)
        setSelectGroupe("")
        if (date != "") {
            let dateComm = date
            let month = "" + (dateComm.getMonth() + 1)
            let day = "" + dateComm.getDate()
            let year = dateComm.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("-")
        } else {
            var convertDate = ""
        }
        const res = await API.post("groupe_examen/getBySemNivSpecMatJourQuartDate", {
            annee_id,
            date: convertDate,
            matiere_id: selectMatiere.value,
            niveau_id: selectNiveau.value,
            semestre_id: selectSemestre.value,
            specialite_id: selectSpecialite.value,
            quart_id: e.value
        }).then(res => {
            setGroupe(res.data.groups)
        })
    }
    const getDetail = async (e) => {
        setSelectGroupe(e)
        if (date != "") {
            let dateComm = date
            let month = "" + (dateComm.getMonth() + 1)
            let day = "" + dateComm.getDate()
            let year = dateComm.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("-")
        } else {
            var convertDate = ""
        }
        const res = await API.post("groupe_examen/details_seance", {
            annee_id,
            date: convertDate,
            matiere_id: selectMatiere.value,
            niveau_id: selectNiveau.value,
            semestre_id: selectSemestre.value,
            specialite_id: selectSpecialite.value,
            quart_id: selectQuart.value,
            id: e.value
        }).then(res => {
            setArrayDetail(res.data.details)
        })
    }
    const getDetailPermuter = async (e) => {
        setSelectGroupePermuter(e)
        if (datePermuter != "") {
            let dateComm = datePermuter
            let month = "" + (dateComm.getMonth() + 1)
            let day = "" + dateComm.getDate()
            let year = dateComm.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("-")
        } else {
            var convertDate = ""
        }
        const res = await API.post("groupe_examen/details_seance", {
            annee_id,
            date: convertDate,
            matiere_id: selectMatierePermuter.value,
            niveau_id: selectNiveauPermuter.value,
            semestre_id: selectSemestrePermuter.value,
            specialite_id: selectSpecialitePermuter.value,
            quart_id: selectQuartPermuter.value,
            id: e.value
        }).then(res => {
            setArrayDetailPermuter(res.data.details)
        })
    }
    const getGroupePermuter = async (e) => {
        setSelectQuartPermuter(e)
        setSelectGroupePermuter("")
        if (datePermuter != "") {
            let dateComm = datePermuter
            let month = "" + (dateComm.getMonth() + 1)
            let day = "" + dateComm.getDate()
            let year = dateComm.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("-")
        } else {
            var convertDate = ""
        }
        const res = await API.post("groupe_examen/getBySemNivSpecMatJourQuartDate", {
            annee_id,
            date: convertDate,
            matiere_id: selectMatierePermuter.value,
            niveau_id: selectNiveauPermuter.value,
            semestre_id: selectSemestrePermuter.value,
            specialite_id: selectSpecialitePermuter.value,
            quart_id: e.value
        }).then(res => {
            setGroupePermuter(res.data.groups)
        })
    }
    const getMatiere = async (e) => {
        setSelectSpecialite(e)
        setSelectMatiere("")
        setDate(new Date())
        setSelectQuart("")
        setSelectGroupe("")
        const res = await API.post("matiere/get_passe_by_niveaux_semestre_specialite", {
            specialite_id: e.value,
            niveau_id: selectNiveau.value,
            semestre_id: selectSemestre.value,
            annee_id
        }).then(res => {
            setMatiere(res.data.Matiere)
        })
    }
    const getMatiere1 = async (e) => {
        setSelectMatiere(e)
        setDate(new Date())
        setSelectQuart("")
        setSelectGroupe("")

    }
    const getMatiere1Permuter = async (e) => {
        setSelectMatierePermuter(e)
        setDatePermuter(new Date())
        setSelectQuartPermuter("")
        setSelectGroupePermuter("")

    }
    const getDate = async (e) => {
        setDate(e)
        setSelectQuart("")
        setSelectGroupe("")

    }
    const getMatierePermuter = async (e) => {
        setSelectSpecialitePermuter(e)
        setSelectMatierePermuter("")
        setDatePermuter(new Date())
        setSelectQuartPermuter("")
        setSelectGroupePermuter("")
        const res = await API.post("matiere/get_passe_by_niveaux_semestre_specialite", {
            specialite_id: e.value,
            niveau_id: selectNiveauPermuter.value,
            semestre_id: selectSemestrePermuter.value,
            annee_id
        }).then(res => {
            setMatierePermuter(res.data.Matiere)
        })
    }
    const save = async () => {
        if (date != "") {
            let dateComm = date
            let month = "" + (dateComm.getMonth() + 1)
            let day = "" + dateComm.getDate()
            let year = dateComm.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("-")
        } else {
            var convertDate = ""
        }
        if (datePermuter != "") {
            let dateCommPermuter = datePermuter
            let monthPermuter = "" + (dateCommPermuter.getMonth() + 1)
            let dayPermuter = "" + dateCommPermuter.getDate()
            let yearPermuter = dateCommPermuter.getFullYear()
            if (monthPermuter.length < 2) monthPermuter = "0" + monthPermuter
            if (dayPermuter.length < 2) dayPermuter = "0" + dayPermuter
            var convertDatePermuter = [yearPermuter, monthPermuter, dayPermuter].join("-")
        } else {
            var convertDatePermuter = ""
        }
        API.post("salle_examen/permuter", {
            id_g1: selectGroupe.value,
            matiere_g1: selectMatiere.value,
            date_g1: convertDate,
            id_g2:selectGroupePermuter.value,
            matiere_g2:selectMatierePermuter.value,
            date_g2:convertDatePermuter,
            semestre_id:selectSemestre.value,
            annee_id,user_id
        }).then(res => {
            props.setSection(3)
        })
    }
    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Row>
                        <Col lg="6">
                            <fieldset style={{border: '1px solid #ccc', padding: '10px'}}>
                                <legend style={{color: "#556ee6"}}>Choisir le groupe qui sera permuter</legend>
                                <Row>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Semestre : </Label>
                                            <Select
                                                options={semestre}
                                                isSearchable={true}
                                                value={selectSemestre}
                                                onChange={e => getSemestre(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Niveau : </Label>
                                            <Select
                                                options={niveau}
                                                isSearchable={true}
                                                value={selectNiveau}
                                                onChange={e => getSpecialite(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Spécialité : </Label>
                                            <Select
                                                options={specialite}
                                                isSearchable={true}
                                                value={selectSpecialite}
                                                onChange={e => getMatiere(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Matière :"} </Label>
                                            <Select
                                                options={matiere}
                                                isSearchable={true}
                                                value={selectMatiere}
                                                onChange={e => getMatiere1(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Date d'examen"}</Label>
                                            <DatePicker
                                                className="form-control ddate"
                                                selected={date}
                                                onChange={e => getDate(e)}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                    </Col>

                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Quart d'examen :"} </Label>
                                            <Select
                                                options={quart}
                                                isSearchable={true}
                                                value={selectQuart}
                                                onChange={e => getGroupe(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Groupe d'examen :"} </Label>
                                            <Select
                                                options={groupe}
                                                isSearchable={true}
                                                value={selectGroupe}
                                                onChange={e => getDetail(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {selectGroupe !== "" ? (
                                    <Row>
                                        <Col lg={12}>
                                            <CardTitle style={{color: "#088d29"}} className="h4">
                                                {"Détail du groupe d'examen"}
                                            </CardTitle>
                                            <table className="table table-bordered text-center">
                                                <tbody>
                                                <tr>
                                                    <td style={{padding: '0'}}>Groupe</td>
                                                    <td style={{padding: '0'}}>Capacité du groupe</td>
                                                    <td style={{padding: '0'}}>Salle</td>
                                                    <td style={{padding: '0'}}>Capacité du salle</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding: '0'}}>
                                                        {arrayDetail.nom_groupe}
                                                    </td>
                                                    <td style={{padding: '0'}}>
                                                        {arrayDetail.capacite_groupe}
                                                    </td>
                                                    <td style={{padding: '0'}}>
                                                        {arrayDetail.salle}
                                                    </td>
                                                    <td style={{padding: '0'}}>
                                                        {arrayDetail.capacite_salle}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                ) : null}
                            </fieldset>
                        </Col>
                        <Col lg="6">
                            <fieldset style={{border: '1px solid #ccc', padding: '10px'}}>
                                <legend style={{color: "#556ee6"}}>Choisir le groupe à permuter</legend>
                                <Row>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Semestre : </Label>
                                            <Select
                                                options={semestrePermuter}
                                                isSearchable={true}
                                                value={selectSemestrePermuter}
                                                onChange={e => getSemestrePermuter(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Niveau : </Label>
                                            <Select
                                                options={niveauPermuter}
                                                isSearchable={true}
                                                value={selectNiveauPermuter}
                                                onChange={e => getSpecialitePermuter(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Spécialité : </Label>
                                            <Select
                                                options={specialitePermuter}
                                                isSearchable={true}
                                                value={selectSpecialitePermuter}
                                                onChange={e => getMatierePermuter(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Matière :"} </Label>
                                            <Select
                                                options={matierePermuter}
                                                isSearchable={true}
                                                value={selectMatierePermuter}
                                                onChange={e => getMatiere1Permuter(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Date d'examen"}</Label>
                                            <DatePicker
                                                className="form-control ddate"
                                                selected={datePermuter}
                                                onChange={e => setDatePermuter(e)}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                    </Col>

                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Quart d'examen :"} </Label>
                                            <Select
                                                options={quartPermuter}
                                                isSearchable={true}
                                                value={selectQuartPermuter}
                                                onChange={e => getGroupePermuter(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Groupe d'examen :"} </Label>
                                            <Select
                                                options={groupePermuter}
                                                isSearchable={true}
                                                value={selectGroupePermuter}
                                                onChange={e => getDetailPermuter(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {selectGroupePermuter !== "" ? (
                                    <Row>
                                        <Col lg={12}>
                                            <CardTitle style={{color: "#088d29"}} className="h4">
                                                {"Détail du groupe d'examen"}
                                            </CardTitle>
                                            <table className="table table-bordered text-center">
                                                <tbody>
                                                <tr>
                                                    <td style={{padding: '0'}}>Groupe</td>
                                                    <td style={{padding: '0'}}>Capacité du groupe</td>
                                                    <td style={{padding: '0'}}>Salle</td>
                                                    <td style={{padding: '0'}}>Capacité du salle</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding: '0'}}>
                                                        {arrayDetailPermuter.nom_groupe}
                                                    </td>
                                                    <td style={{padding: '0'}}>
                                                        {arrayDetailPermuter.capacite_groupe}
                                                    </td>
                                                    <td style={{padding: '0'}}>
                                                        {arrayDetailPermuter.salle}
                                                    </td>
                                                    <td style={{padding: '0'}}>
                                                        {arrayDetailPermuter.capacite_salle}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                ) : null}
                            </fieldset>
                        </Col>
                    </Row>
                </Form>
                {arrayDetail !== "" && arrayDetailPermuter !=="" ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        {" "}
                        <Col lg="6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                                <Button
                                    type="button"
                                    color="warning"
                                    className="btn btn-warning  mb-2 me-2"
                                    onClick={props.back}
                                >
                                    Annuler
                                </Button>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                                <button type="button" className="btn btn-primary " onClick={save}>
                                    CONFIRMER
                                </button>
                            </div>
                        </Col>
                    </div>
                ):null}

            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}
export default EditSalleExamen
EditSalleExamen.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    semestre: PropTypes.object,
}

import React, {useEffect, useState} from "react"
import {Button, Col, Form, Input, Label, Modal, ModalBody, Row, Table} from "reactstrap"
import PropTypes from "prop-types"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import API from "../../api"
import Select from "react-select";

const ChevauchementGroupe = props => {
    const [list, setList] = useState([])
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    useEffect(async () => {
        const res = await API.post("surveillance_examen/verif_chevauchement", {
            annee_id,
            semestre_id: props.semestre.value
        }).then(res => {
            setList(res.data.elements)
        })
    }, [props.semestre])

    return (
        <React.Fragment>
            <Row>

                <div>
                    <Table className="mt-3" striped bordered hover>
                        <thead>
                        <tr>
                            <th style={{textAlign: "center"}}>Matière</th>
                            <th style={{textAlign: "center"}}>Groupe</th>
                            <th style={{textAlign: "center"}}>Salle</th>
                            <th style={{textAlign: "center"}}>Date</th>
                            <th style={{textAlign: "center"}}>Heure début</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((el, index) => (
                            <>
                                {el.matieres.map((_, i) => (
                                    <tr key={`${index}-${i}`}>
                                        <td style={{textAlign: "center"}}>
                                            {el.matieres[i]}
                                        </td>
                                        {i === 0 && (
                                            <>
                                                <td rowSpan={el.matieres.length} style={{textAlign: "center"}}>
                                                    {el.groupe}
                                                </td>
                                                <td rowSpan={el.matieres.length} style={{textAlign: "center"}}>
                                                    {el.salle}
                                                </td>
                                                <td rowSpan={el.matieres.length} style={{textAlign: "center"}}>
                                                    {el.date}
                                                </td>
                                                <td rowSpan={el.matieres.length} style={{textAlign: "center"}}>
                                                    {el.heure_debut}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </>
                        ))}
                        </tbody>
                    </Table>
                </div>

            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />

        </React.Fragment>
    )
}

export default ChevauchementGroupe
ChevauchementGroupe.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    semestre: PropTypes.object,
}

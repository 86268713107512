import React, { useState } from "react"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"

const AddSalle = props => {
  const [name, setName] = useState("")
  const [capacite, setCapacite] = useState("");
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  const save = async () => {
    if (name != "") {
      const res = await API.post("salle/add", {
        name: name,
        capacite:capacite,
        user_id,
        annee_id
      })
        .then(res => {
          props.setSection(3)
        })
    } else {
      toast.error("⛔ InstitutOrigin obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Nom QuartExamen</Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder="Nom QuartExamen"
                  onChange={e => setName(e.target.value)}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Capacité</Label>
                <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    placeholder="Capacite"
                    onChange={e => setCapacite(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                CONFIRMER
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddSalle
AddSalle.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, Modal, ModalHeader, ModalBody, Button, Label } from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import "react-datepicker/dist/react-datepicker.css"
const DetailDemandeTerrain = props => {
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id

  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [etat, setEtat] = useState("");
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "enseignant",
      text: "Enseignant",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "groupe",
      text: "Groupe",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: "Matière",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date",
      text: "Date",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "heure_debut",
      text: "Heure début",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "duree",
      text: "Durée",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },

    {
      dataField: "Ajouter Demande",
      isDummyField: true,
      text: "Ajouter Demande",
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
            row.etat ===1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                  style={{color: "#788ff6", cursor: "pointer"}}
                  onClick={() => toggle(row)}
                  className="fas fa-cogs font-size-18"
                ></i>
              </div>
            )  : row.etat === 2 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  style={{ color: "#18ae3d", cursor: "pointer" }}
                  className="fas fa-check font-size-18"
                  onClick={() => toggle(row)}
                  title="Ce demande est validée"
                ></i>
              </div>
            ):row.etat === 3 ?  (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  style={{ color: "#ff0000", cursor: "pointer" }}
                  className="fas fa-times font-size-18"
                  onClick={() => toggle(row)}
                  title="Cette demande est refusée"
                ></i>
              </div>
            ): (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  style={{ color: "#a7a3a3", cursor: "pointer" }}
                  className="fas fa-plus-circle font-size-18"
                  title="Ce grade déja utilisé"
                ></i>
              </div>
            )
    },
  ]

  const { SearchBar } = Search
  const toggle = row => {
    setModal(!modal)
    setId(row.id)
    setEtat(row.etat)
  }
  const toggleSave = async () => {
      const res = await API.post("demandeTerrain/validation", {
        id: id,
        annee_id,user_id
      }).then(res => {
        setModal(false)
        const resD = API.post("demandeTerrain/list_scolarite",{
          semestre_id: props.semestre_value,
          annee_id:annee_id
        }).then(resD => {
          setOrders(resD.data.demandes)
          setLoading(true)
        })
      })
  }
  const toggleRefus = async () => {
      const res = await API.post("demandeTerrain/refus", {
        id: id,
        annee_id,user_id
      }).then(res => {
        setModal(false)
        const resD = API.post("demandeTerrain/list_scolarite",{
          semestre_id: props.semestre_value,
          annee_id:annee_id
        }).then(resD => {
          setOrders(resD.data.demandes)
          setLoading(true)
        })
      })
  }
  useEffect(async () => {
      const res = API.post("demandeTerrain/list_scolarite", {
        semestre_id: props.semestre_value,
        annee_id:annee_id
      }).then(res => {
        setOrders(res.data.demandes)
        setLoading(true)
      })

  }, [props.semestre_value])
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row className="mt-4">
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
          style={{width: "100% !important", textAlign: "center !important",display: "flex", justifyContent: "center"}}
          toggle={toggle}
          tag="h4"
        >
          Valider une date pour la demande de terrain.
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{display: "flex", justifyContent: "center", margin:"8px"}}>
              <p>
                {"Voulez-vous valider / invalider cette demande de terrain ? "}
              </p>
            </div>
            <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
              }}
            >
              {etat === 1 ? (
                      <div>
                        <Button
                            color="info"
                            className="btn-rounded "
                            onClick={toggleSave}
                        >
                          <i
                              style={{color: "white"}}
                              className="far fa-thumbs-up"
                          ></i>
                          Validée
                        </Button>
                        <Button
                            onClick={toggleRefus}
                            color="danger"
                            className="btn-rounded "
                        >
                          <i
                              style={{color: "white"}}
                              className="far fa-thumbs-down"
                          ></i>
                          Refusée
                        </Button>
                      </div>
                ):null}
              {etat === 2 ? (
                      <div>
                        <Button
                            onClick={toggleRefus}
                            color="danger"
                            className="btn-rounded "
                        >
                          <i
                              style={{color: "white"}}
                              className="far fa-thumbs-down"
                          ></i>
                          Refusée
                        </Button>
                      </div>
                ):null}
              {etat === 3 ? (
                      <div>
                        <Button
                            color="info"
                            className="btn-rounded "
                            onClick={toggleSave}
                        >
                          <i
                              style={{color: "white"}}
                              className="far fa-thumbs-up"
                          ></i>
                          Validée
                        </Button>
                      </div>
                ):null}

            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
          transition={Slide}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DetailDemandeTerrain)
DetailDemandeTerrain.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  t: PropTypes.any,
}

import React, {useEffect, useState} from "react"
import {Button, Col, Form, Input, Label, Modal, ModalBody, Row, Table} from "reactstrap"
import PropTypes from "prop-types"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import API from "../../api"
import Select from "react-select";

const AddGroupeExamen = props => {

    const [semestre, setSemestre] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
        {value: 3, label: "Session Contôle", section: 2},
    ])
    const [cycle, setCycle] = useState([
        {value: 1, label: "Licence"},
        {value: 2, label: "Mastère"}
    ])
    const [selectSemestre, setSelectSemestre] = useState("")
    const [niveau, setNiveau] = useState([])
    const [selectNiveau, setSelectNiveau] = useState("")
    const [specialite, setSpecialite] = useState([])
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [nombreTotalEtd, setNombreTotalEtd] = useState(0);
    const [nbEtdGrp, setNbEtdGrp] = useState(0);
    const [nbGroupe, setNbGroupe] = useState(0);
    const [reste, setReste] = useState(0);
    const [addGroup, setAddGroup] = useState(0);
    const [disabledGroupePlus, setDisabledGroupePlus] = useState(true);
    const [disabledGroupeDistribuer, setDisabledGroupeDistribuer] = useState(true);
    const [affiche, setAffiche] = useState(false)
    const [list, setList] = useState([{capacite: "", key: "", nom: "", salle_id: ""}])
    const [addSalle, setAddSalle] = useState(2);
    const [salle, setSalle] = useState([])
    const [modalDetail, setModalDetail] = useState(false)
    const [title, setTitle] = useState("")
    const [msg, setMsg] = useState("")

    const [selectCycle, setSelectCycle] = useState("");
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    const toggleDetail = async (title,msg) => {
        setModalDetail(!modalDetail)
        if(modalDetail !== true){
            setTitle(title)
            setMsg(msg)
        }
    }
    useEffect(async () => {
        const res = await API.get("niveau/select").then(res => {
            setNiveau(res.data.Niveau)
        })
    }, [])
    const save = async () => {
        if (addSalle === 1) {
            let isValid = true;
            list.forEach((el, index) => {
                if (!el.salle_id) {
                    isValid = false;
                    toggleDetail("Champs obligatoire.", "⛔ Il faut choisir une salle pour chaque groupe d'examen.");
                }
            });
            if (isValid) {
                try {
                    const res = await API.post("groupe_examen/save", {
                        specialite_id: selectSpecialite.value,
                        semestre_id: selectSemestre.value,
                        niveau_id: selectNiveau.value,
                        nb_par_groupe: nbEtdGrp,
                        nb_total: nombreTotalEtd,
                        nb_group: nbGroupe,
                        reste_etudiant: reste,
                        listGroups: list,
                        annee_id,
                        user_id
                    });
                    props.setSection(1);
                } catch (error) {
                    toggleDetail("Erreur lors de l'enregistrement du groupe d'examen:", "⛔ \"Erreur lors de l'enregistrement du groupe d'examen");
                }
            }
        }

    }
    const getSpecialite = async event => {
        setSelectNiveau(event)
        setSelectSpecialite("")
        setNombreTotalEtd(0)
        setNbEtdGrp(0)
        setNbGroupe(0)
        setReste(0)
        setList([])
        setAffiche(false)
        const res = await API.post("groupe_examen/get_list_spec", {
            niveau_id: event.value,
            semestre_id: [selectSemestre],
            cycle_id:selectCycle.value
        }).then(res => {
            setSpecialite(res.data.Groups)
        })
    }
    const getSemestre = async event => {
        setSelectSemestre(event)
        setSelectNiveau("")
        setSelectSpecialite("")
        setNombreTotalEtd(0)
        setNbEtdGrp(0)
        setNbGroupe(0)
        setReste(0)
        setAffiche(false)
        setList([])
    }
    const getCycle = async event => {
        setSelectCycle(event)
        setSelectSemestre("")
        setSelectNiveau("")
        setSelectSpecialite("")
        setNombreTotalEtd(0)
        setNbEtdGrp(0)
        setNbGroupe(0)
        setReste(0)
        setAffiche(false)
        setList([])
    }
    const getNbGrouo = async event => {
        setSelectSpecialite(event)
        setNombreTotalEtd(0)
        setNbEtdGrp(0)
        setNbGroupe(0)
        setReste(0)
        setAffiche(false)
        setList([])
        const res = await API.post("groupe_examen/get_nb_etudiants", {
            specialite_id: event.value,
            niveau_id: selectNiveau.value,
            semestre_id: selectSemestre.value,
            annee_id
        }).then(res => {
            setNombreTotalEtd(res.data.nb_etud)
        })
    }
    const getVider = async event => {
        setNombreTotalEtd(event.target.value)
        setNbEtdGrp(0)
        setReste(0)
        setNbGroupe(0)
        setList([])
    }
    const handleKeyUp = () => {
        const reste_grp = Math.floor(nombreTotalEtd % nbEtdGrp)
        if (reste_grp === 0) {
            setDisabledGroupeDistribuer(true)
            setDisabledGroupePlus(true)
        } else {
            setDisabledGroupeDistribuer(false)
            setDisabledGroupePlus(false)
        }
        setNbGroupe(Math.floor(nombreTotalEtd / nbEtdGrp))
        setReste(reste_grp)
        setList([])
    };
    const handleRadioChange = (value) => {
        setAddGroup(value);
        setList([])
        setAffiche(false)
        setList([])
        if (value === 1) {
            setNbGroupe((prev) => prev + 1);
            setReste(0)
        } else {
            handleKeyUp()
        }
    };
    const handleRadioChangeSalle = (value) => {

        setAddSalle(value);
        setAffiche(false)
        setList([])
        if (value === 1) {
            API.post("salle_examen/get_non_choisis", {
                semestre_id: selectSemestre.value,
                annee_id
            }).then(res => {
                setSalle(res.data.salles_non_choisi)
            })
        }
    };

    const addSalleLigne = (event, index) => {
        let salle_id = event
        setList(
            list.map((el, id) =>
                id === index ? Object.assign(el, {salle_id: salle_id}) : el
            )
        )
    }
    const display = () => {
        setList([])
        if (selectSemestre === "" || selectNiveau === "" || selectSpecialite === "" || nombreTotalEtd === 0 || nbEtdGrp === 0) {
            setAffiche(false)
            toast.error("⛔ Tous les champs sont obligatoire !", {
                containerId: "A",
            })
        } else {
            setAffiche(true)
            API.post("groupe_examen/generate_repartition", {
                specialite_id: selectSpecialite.value,
                semestre_id: selectSemestre.value,
                niveau_id: selectNiveau.value,
                nb_par_groupe: nbEtdGrp,
                nb_total: nombreTotalEtd,
                choix: addGroup,
                annee_id
            }).then(res => {
                if(res.data.errNum ===301 || res.data.errNum ==="301"){
                    toast.error("⛔ "+res.data.msg, {
                        containerId: "A",
                    })
                }else{
                    setList(res.data.res)
                }

            })
        }
    }
    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Row>
                        <Col md={3}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Cycle:</Label>
                                <Select
                                    options={cycle}
                                    isSearchable={true}
                                    onChange={e => getCycle(e)}
                                    value={selectCycle}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Semestre:</Label>
                                <Select
                                    options={semestre}
                                    isSearchable={true}
                                    onChange={e => getSemestre(e)}
                                    value={selectSemestre}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Niveau</Label>
                                <Select
                                    options={niveau}
                                    isSearchable={true}
                                    onChange={e => getSpecialite(e)}
                                    value={selectNiveau}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité</Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    onChange={e => getNbGrouo(e)}
                                    value={selectSpecialite}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label
                                    for="basicpill-firstname-input1">{"Nombre total d'étudiants de la spécialité :"}</Label>
                                <Input
                                    className="form-control"
                                    placeholder="Nombre total d'étudiants de la spécialité"
                                    type="number"
                                    value={nombreTotalEtd}
                                    onChange={e => getVider(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">{"Nombre d'étudiants dans le groupe :"}</Label>
                                <Input
                                    className="form-control"
                                    placeholder="Nombre d'étudiants dans le groupe"
                                    value={nbEtdGrp}
                                    type="number"
                                    onChange={e => setNbEtdGrp(e.target.value)}
                                    onKeyUp={handleKeyUp}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">{"Nombre de groupes retournés :"}</Label>
                                <Input
                                    className="form-control"
                                    placeholder="Nombre de groupes retournés"
                                    type="number"
                                    value={nbGroupe}
                                    readOnly
                                    onChange={e => setNbGroupe(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label
                                    for="basicpill-firstname-input1">{"Nombre d'étudiants reste  dans le groupe :"}</Label>
                                <Input
                                    className="form-control"
                                    placeholder="Nombre d'étudiants reste  dans le groupe"
                                    type="number"
                                    value={reste}
                                    readOnly
                                    onChange={e => setReste(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="d-block mb-3">Choix de répartition :</Label>
                                <div className="form-check form-check-inline">
                                    <Input
                                        type="radio"
                                        id="customRadioInline1"
                                        name="customRadioInline1"
                                        className="form-check-input"
                                        checked={addGroup === 1}
                                        onChange={() => handleRadioChange(1)}
                                        // onChange={e => setAddGroup(1)}
                                        disabled={disabledGroupePlus}
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="customRadioInline1"
                                    >
                                        Ajouter un autre groupe pour le reste des étudiants
                                    </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                    <Input
                                        type="radio"
                                        id="customRadioInline2"
                                        name="customRadioInline1"
                                        className="form-check-input"
                                        checked={addGroup === 2}
                                        onChange={() => handleRadioChange(2)}
                                        // onChange={e => setAddGroup(2)}
                                        disabled={disabledGroupeDistribuer}
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="customRadioInline2"
                                    >
                                        Distribuer le reste des étudiants pour les autres groupes
                                    </Label>
                                </div>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="d-block mb-3">Choix de Distribution des salles :</Label>
                                <div className="form-check form-check-inline">
                                    <Input
                                        type="radio"
                                        id="customRadioInline12"
                                        name="customRadioInline12"
                                        className="form-check-input"
                                        checked={addSalle === 1}
                                        onChange={() => handleRadioChangeSalle(1)}
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="customRadioInline12"
                                    >
                                        {"Distribuer les salles pour chaque groupe d'examen"}
                                    </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                    <Input
                                        type="radio"
                                        id="customRadioInline22"
                                        name="customRadioInline12"
                                        className="form-check-input"
                                        checked={addSalle === 2}
                                        onChange={() => handleRadioChangeSalle(2)}
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="customRadioInline22"
                                    >
                                        {"Distribuer automatiquement les salles pour chaque groupe d'examen"}
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        {" "}

                        <Col lg="6">
                            <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={display}>
                                    Afficher
                                </button>
                            </div>
                        </Col>
                    </div>
                    {affiche ? (
                        <div>
                            <Table className="mt-3" striped bordered hover>
                                <thead>
                                <tr>
                                    <th style={{textAlign: "center"}}></th>
                                    <th style={{textAlign: "center"}}>Nom groupe</th>
                                    <th style={{textAlign: "center"}}>Capacité</th>
                                    {addSalle === 1 ? (
                                        <th style={{textAlign: "center"}}>Choix de salle</th>
                                    ) : null}

                                </tr>
                                </thead>
                                <tbody>
                                {list.map((el, index) => (
                                    <tr key={index}>
                                        <td style={{textAlign: "center"}}>{el.key}</td>
                                        <td style={{textAlign: "center"}}>{el.nom}</td>
                                        <td style={{textAlign: "center"}}>{el.capacite}</td>
                                        {addSalle === 1 ? (
                                            <td style={{textAlign: "center"}}>
                                                <Select
                                                    options={salle}
                                                    isSearchable={true}
                                                    onChange={e => addSalleLigne(e, index)}
                                                    value={el.salle_id}
                                                />
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    ) : null}
                </Form>
                {affiche ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        {" "}
                        <Col lg="6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                                <Button
                                    type="button"
                                    color="warning"
                                    className="btn btn-warning  mb-2 me-2"
                                    onClick={props.back}
                                >
                                    Annuler
                                </Button>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                                <button type="button" className="btn btn-primary " onClick={save}>
                                    CONFIRMER
                                </button>
                            </div>
                        </Col>
                    </div>
                ) : null}
            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
            <Modal isOpen={modalDetail} toggle={toggleDetail} centered={true}>
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "17px"
                    }}
                    toggle={toggleDetail}
                >
                    {title}
                </div>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>{msg}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                                marginTop:"17px"
                            }}
                        >

                            <div>
                                <Button
                                    onClick={toggleDetail}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Fermer
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddGroupeExamen
AddGroupeExamen.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
}

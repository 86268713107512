import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, Modal, ModalHeader, ModalBody } from "reactstrap"
import { withRouter } from "react-router"
import paginationFactory from "react-bootstrap-table2-paginator";

const DataTableHistoriqueVueEmploi = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    alwaysShowAllBtns: true,
    sizePerPage: 20,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  const Columns = () => [
    {
      dataField: "enseignant",
      text: "Enseignant",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    }
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.post("enseignant/getHistoriqueVuEmploi",{annee_id}).then(res => {
      setOrders(res.data.hist)
      setLoading(true)
    })
  }, [])
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...{" "}
            </h4>
          </div>
        )}
      </div>

    </React.Fragment>
  )
}

export default withRouter(DataTableHistoriqueVueEmploi)
DataTableHistoriqueVueEmploi.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";

const AddDiplome = props => {
  const [nom, setNom] = useState("")
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  useEffect(async () => {
    const res = await API.get("cycle/select").then(res => {
      setCycle(res.data.Cycle)
    })
  }, [])
  const save = async () => {
    if (nom != "" && selectCycle!= "") {
      const res = await API.post("diplome/add", {
        name: nom,
        cycle_id:selectCycle.value,user_id,annee_id
      }).then(res => {
        props.setSection(1)
      })
    } else {
      toast.error("⛔ Nom et cycle sont obligatoires", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Form>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Cycle</Label>
                  <Select
                      options={cycle}
                      isSearchable={true}
                      onChange={e => setSelectCycle(e)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Nom")} :
                  </Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    placeholder={props.t("Nom")}
                    onChange={e => setNom(e.target.value)}
                  />
                </div>
              </Col>  
            </Row>
          </Form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={save}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </AvForm>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddDiplome)
AddDiplome.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
